import { render, staticRenderFns } from "./AccountRemovalBar.vue?vue&type=template&id=59bd2889&"
import script from "./AccountRemovalBar.vue?vue&type=script&lang=js&"
export * from "./AccountRemovalBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2082127983/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59bd2889')) {
      api.createRecord('59bd2889', component.options)
    } else {
      api.reload('59bd2889', component.options)
    }
    module.hot.accept("./AccountRemovalBar.vue?vue&type=template&id=59bd2889&", function () {
      api.rerender('59bd2889', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/common/AccountRemovalBar.vue"
export default component.exports